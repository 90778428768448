<template>
    <div class="form-box">
        <div class="form-head">
            <div class="line"></div>
            <div class="title">
                <span>*</span>
                <span>{{ label }}</span>
            </div>
        </div>
        <div class="form-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    name:'form-box',
    props:{
        label:String,
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.form-box {
    width: 100%;
    width: 840px;
    border: 1px solid #EEEEEE;
    margin-bottom: 24px;

    .form-head {
        width: 100%;
        height: 56px;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        align-items: center;

        .line {
            width: 2px;
            height: 14px;
            background: #333333;
            margin-right: 24px;
        }

        .title {
            font-size: 16px;

            span {
                &:nth-child(1) {
                    color: #FF2323;
                }

                &:nth-child(2) {
                    color: #333333;
                }
            }
        }
    }

    .form-content {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 16px 24px;
        input {
            flex: 1;
            resize: none;
            border: none;
            outline: none;
            box-shadow: none;
            color: #333333;
        }
    }
}
</style>